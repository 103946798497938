<template>
    <div class="progress_row">
        <div class="top_area mb-2">
            <div class="filter_dropdown mr-auto" @click.stop="dropdownToggle($event)" v-click-outside="closeDropdown">
                {{ tab == 1 ? 'Tracking Uploads' : 'Habit Uploads'}}<i class="fas fa-chevron-down"></i>
                <div class="dropdown_wpr">
                    <ul>
                        <li class="filter_btn" @click="toggleTab(1)">Tracking Uploads</li>
                        <li class="filter_btn" @click="toggleTab(2)">Habit Uploads</li>
                    </ul>
                </div>
            </div>
            <div class="filter_dropdown" @click.stop="dropdownToggle($event)" v-click-outside="closeDropdown">
                {{ progressTracking.name }}<i class="fas fa-chevron-down"></i>
                <div class="dropdown_wpr">
                    <ul class="scroll_bar">
                        <li v-for="(progress, p) of progresses" :key="p" @click="progressTracking = progress; progressDropdown = false;">{{ progress.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="actions mt-2">
            <ul>
                <li class="filter_btn">
                    <div class="btn_item pointer" :class="tab === 1 ? 'active' : ''" @click="toggleTab(1)">Tracking Uploads</div>
                </li>
                <li class="filter_btn">
                    <div class="btn_item pointer" :class="tab === 2 ? 'active' : ''" @click="toggleTab(2)">Habit Uploads</div>
                </li>
            </ul>
            <div class="optionDrops ml-auto" v-if="tab === 1" @click.self="progressDropdown = !progressDropdown"  v-click-outside="closeProgressDropdown">
                {{ progressTracking.name }} <i class="fas fa-angle-down"></i>
                <div class="dropdown_wpr" :class="progressDropdown ? 'active' : ''">
                    <ul>
                        <li v-for="(progress, p) of progresses" :key="p" @click="progressTracking = progress; progressDropdown = false;">{{ progress.name }}</li>
                    </ul>
                </div>
            </div>
        </div> -->
        <div class="overview" v-if="tab === 1">
            <div class="report_section" :style="`--var-element-bg-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_color : '#f5f5f5'}; --var-element-text-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_text_color : '#121525'}; --var-element-light-color: ${progressTracking.public_settings ? progressTracking.public_settings.element_color : '#f5f5f5'}20;`">
                <div class="report_container" v-if="clusterImages.length">
                    <!-- <div class="section_header mt-5">
                        <h2>Pictures</h2>
                    </div>
                    <ul class="picture_section" v-for="(clusters, c) of clusterImages" :key="c">
                        <li v-for="(progress, p) of clusters" :key="p">
                            <ul class="section_wpr">
                                <li>
                                    <div class="section_title">
                                        <h4>Front</h4>
                                    </div>
                                    <div class="img_viewer">
                                        <img @click="previewImage([{ title: 'Front', src: progress.front_photo }, { title: 'Side', src: progress.side_photo }, { title: 'Back', src: progress.back_photo }])" :src="progress.front_photo">
                                        <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Front', src: progress.front_photo }, { title: 'Side', src: progress.side_photo }, { title: 'Back', src: progress.back_photo }])"><i class="fas fa-search-plus"></i></button>
                                        <a type="button" class="download_btn" :href="progress.front_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                    </div>
                                </li>
                                <li>
                                    <div class="section_title">
                                        <h4>Side</h4>
                                    </div>
                                    <div class="img_viewer">
                                        <img @click="previewImage([{ title: 'Side', src: progress.side_photo }, { title: 'Back', src: progress.back_photo }, { title: 'Front', src: progress.front_photo }])" :src="progress.side_photo">
                                        <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Side', src: progress.side_photo }, { title: 'Back', src: progress.back_photo }, { title: 'Front', src: progress.front_photo }])"><i class="fas fa-search-plus"></i></button>
                                        <a type="button" class="download_btn" :href="progress.side_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                    </div>
                                </li>
                                <li>
                                    <div class="section_title">
                                        <h4>Back</h4>
                                    </div>
                                    <div class="img_viewer">
                                        <img @click="previewImage([{ title: 'Back', src: progress.back_photo }, { title: 'Front', src: progress.front_photo }, { title: 'Side', src: progress.side_photo }])" :src="progress.back_photo">
                                        <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Back', src: progress.back_photo }, { title: 'Front', src: progress.front_photo }, { title: 'Side', src: progress.side_photo }])"><i class="fas fa-search-plus"></i></button>
                                        <a type="button" class="download_btn" :href="progress.back_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul> -->
                    <div class="pic_wrapper">
                        <h3 class="title"><img src="/images/selfie.svg"> Pictures</h3>
                        <swiper :breakpoints="{'499': {slidesPerView: 1}, '599': {slidesPerView: 2}}" :spaceBetween="30" :navigation='true' class="pic_slider">
                            <swiper-slide v-for="(clusters, c) of clusterImages" :key="c">
                                <div class="picture_section" v-for="(progress, p) of clusters" :key="p">
                                    <!-- <div class="date">{{ item.date }}</div> -->
                                    <ul class="section_wpr">
                                        <li>
                                            <div class="section_title">
                                                <h4>Front</h4>
                                            </div>
                                            <div class="img_viewer">
                                                <img @click="previewImage([{ title: 'Front', src: progress.front_photo }, { title: 'Side', src: progress.side_photo }, { title: 'Back', src: progress.back_photo }])" :src="progress.front_photo">
                                                <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Front', src: progress.front_photo }, { title: 'Side', src: progress.side_photo }, { title: 'Back', src: progress.back_photo }])"><i class="fas fa-search-plus"></i></button>
                                                <a type="button" class="download_btn" :href="progress.front_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="section_title">
                                                <h4>Side</h4>
                                            </div>
                                            <div class="img_viewer">
                                                <img @click="previewImage([{ title: 'Side', src: progress.side_photo }, { title: 'Back', src: progress.back_photo }, { title: 'Front', src: progress.front_photo }])" :src="progress.side_photo">
                                                <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Side', src: progress.side_photo }, { title: 'Back', src: progress.back_photo }, { title: 'Front', src: progress.front_photo }])"><i class="fas fa-search-plus"></i></button>
                                                <a type="button" class="download_btn" :href="progress.side_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="section_title">
                                                <h4>Back</h4>
                                            </div>
                                            <div class="img_viewer">
                                                <img @click="previewImage([{ title: 'Back', src: progress.back_photo }, { title: 'Front', src: progress.front_photo }, { title: 'Side', src: progress.side_photo }])" :src="progress.back_photo">
                                                <button type="button" class="lightbox_btn" @click="previewImage([{ title: 'Back', src: progress.back_photo }, { title: 'Front', src: progress.front_photo }, { title: 'Side', src: progress.side_photo }])"><i class="fas fa-search-plus"></i></button>
                                                <a type="button" class="download_btn" :href="progress.back_photo" download target="_blank"><i class="fas fa-download"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <vue-easy-lightbox :visible="previewImagePanel" :index="selectdImageIndex" :imgs="availableImages" @hide="previewImagePanel = false"></vue-easy-lightbox>
                    </div>
                </div>
                <div class="report_container empty_section" v-else>
                    <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
                    <h1 class="text-center">You have not uploaded any pictures yet.</h1>
                </div>
            </div>
        </div>
        <div class="overview" v-if="tab === 2">
            <div class="report_section" :style="`--var-element-bg-color: ${habitTracking.public_settings ? habitTracking.public_settings.element_color : '#f5f5f5'}; --var-element-text-color: ${habitTracking.public_settings ? habitTracking.public_settings.element_text_color : '#121525'}; --var-element-light-color: ${habitTracking.public_settings ? habitTracking.public_settings.element_color : '#f5f5f5'}20;`">
                <div class="report_container" v-if="images.length">
                    <div class="section_header mt-5">
                        <h2>Pictures</h2>
                    </div>
                    <ul class="picture_section">
                        <li v-for="(image, i) of images" :key="i">
                            <div class="proof_item">
                                <h6>{{ moment(image.created_at).format('ll') }}</h6>
                                <div class="img_viewer">
                                    <img @click="previewImage(image.file)" :src="image.file">
                                    <button type="button" class="lightbox_btn"  @click="previewImage(image.file)"><i class="fas fa-search-plus"></i></button>
                                    <a type="button" class="download_btn" :href="image.file" download target="_blank"><i class="fas fa-download"></i></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="pagination">
                        <vue-pagination v-model="params.page" :pages="params.last_page" :range-size="0" @update:modelValue="handlePagination" />
                    </div>
                    <vue-easy-lightbox :visible="previewImagePanel" :index="selectdImageIndex" :imgs="availableImages" @hide="previewImagePanel = false"></vue-easy-lightbox>
                </div>
                <div class="report_container empty_section" v-else>
                    <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
                    <h1 class="text-center">You have not uploaded any pictures yet.</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import moment from 'moment'
import VueEasyLightbox from 'vue-easy-lightbox'
import VuePagination from '@hennge/vue3-pagination'

import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.min.css'
SwiperCore.use([Navigation, Pagination])

export default {
    data () {
        return  {
            tab: 1,
            previewImagePanel: false,
            selectdImageIndex: 0,
            clusterImages: [],
            progressImages: [],
            moment: moment,
            allImages: [],
            gallery: [],
            progressTracking: {},
            progressDropdown: false,
            habitTracking: {},
            loader: false,
            images: [],
            availableImages: [],
            params: {
                page: 1,
                total: 0,
                from: 0,
                to: 0,
            },
        };
    },

    props:{
        dropdownToggle: Function,
        closeDropdown: Function, 
    },

    watch: {
        progressTracking (tracking) {
            const vm = this;

            vm.getCurrentProgressImages(tracking.id);
        },

        habitTracking (habit) {
            const vm = this;

            vm.getHabitImages(habit.id);
        },

        progresses: {
            handler (progresses) {
                const vm = this;

                vm.progressTracking = progresses[0] ? progresses[0] : {};
            },
            deep: true,
        },

        habits: {
            handler (habits) {
                const vm = this;

                vm.habitTracking = habits[0] ? habits[0] : {};
            },
            deep: true,
        },
    },

    components: {
        VueEasyLightbox,
        VuePagination,
        Swiper,
        SwiperSlide,
    },

    computed: mapState({
        progresses: state => state.progressTrackingModule.allProgressTrackings,
        habits: state => state.habitTrackingModule.allHabitTrackings,
    }),

    mounted () {
        const vm = this;

        vm.tab = 1;

        vm.progressTracking = vm.progresses[0] ? vm.progresses[0] : {};
        vm.habitTracking    = vm.habits[0] ? vm.habits[0] : {};
    },

    methods: {
        toggleTab (tab) {
            const vm = this;

            vm.tab = tab;
        },

        closeProgressDropdown () {
            const vm = this;

            vm.progressDropdown = false;
        },

        getCurrentProgressImages (id) {
            const vm = this;

            vm.clusterImages  = [];
            vm.progressImages = [];
            vm.allImages      = [];
        },

        getHabitImages () {
            const vm = this;

            vm.images           = [];
            vm.params.total     = [];
            vm.params.from      = [];
            vm.params.to        = [];
            vm.params.last_page = [];
            vm.availableImages  = [];
        },

        previewImage (image) {
            const vm = this;

            vm.previewImagePanel  = true;
            vm.availableImages    = image;
        },

        handlePagination (page) {
            const vm = this;

            this.params.page = page;
            this.getHabitImages();
        },
    },


}
</script>

<style>
.actions {
    display: flex;
}

.actions>ul {
    list-style-type: none;
    display: flex;
    height: 51px;
}

.actions>ul {
    align-items: center;
    gap: 15px;
    background: transparent;
}

.actions>ul li {
    flex: 0 0 auto;
    border: 0;
    background: transparent;
}

.actions>ul li:first-child {
    padding-left: 0;
}

.actions>ul li:last-child {
    padding-right: 0;
}

.actions .filter_btn .btn_item.active {
    color: #121525;
    font-weight: 500;
}

.actions .optionDrops {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: left;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.actions .optionDrops i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}

.actions .optionDrops .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
    overflow: hidden;
}

.actions .optionDrops ul li {
    flex: 1 1 auto;
    border: 0;
    background: transparent;
}

.dropdown_wpr ul li {
    padding: 8px 14px;
}

.el-color {
    color: var(--var-element-color, #0d6efd) !important;
}

.pic-label {
    background-color: var(--var-element-light-color, #0d6efd) !important;
    /* color: var(--var-element-text-color, #FFF) !important; */
}

.picture_section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.picture_section>li {
    padding: 5px 15px;
    width: 33.333%;
}

.picture_section>li .section_wpr {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
}

.picture_section .section_wpr li:not(:last-child) {
    border-right: 1px solid #eaeaea;
}

.picture_section .section_wpr li {
    flex: 1;
    line-height: 0;
}

.picture_section .section_wpr li .section_title {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    background: #fff;
}

.picture_section .section_wpr li .section_title h4 {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-align: left;
    color: #121525;
}

.picture_section .section_wpr li .section_title h4 span {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    display: block;
    padding-top: 3px;
    color: #757575;
}

.picture_section .section_wpr li .section_title i {
    font-size: 12px;
    width: 25px;
    height: 25px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border: 1px solid #e9e9e9;
    border-radius: 50%;
    margin-left: 5px;
    color: #2f7eed;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.picture_section .section_wpr li img {
    width: 100%;
}

.img_viewer {
    position: relative;
    overflow: hidden;
}

.img_viewer .lightbox_btn {
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    font-size: 11px;
    color: #121525;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -20px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.img_viewer .download_btn {
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    font-size: 11px;
    color: #121525;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -20px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.img_viewer:hover .lightbox_btn {
    left: 10px;
}

.img_viewer:hover .download_btn {
    right: 10px;
}

.empty_section {
    /* border: 1px solid #D9D9D9; */
    border-radius: 10px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.empty_section img{
    max-height: 90px;
    width: auto;
    margin-bottom: 30px;
}
.empty_section h1 {
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    color: #2c3e50;
    /* font-style: italic; */
}

.picture_section .proof_item {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    line-height: 0;
    overflow: hidden;
}

.picture_section .proof_item h6 {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    padding: 12px 15px;
    background: #fff;
    text-align: left;
    border-bottom: 1px solid #e9e9e9;
}
.overview{
    width: 100%;
}
.picture_section .proof_item img {
    width: 100%;
    object-fit: cover;
}
.progress_row .top_area{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
}
.filter_dropdown{
    padding: 6px 15px;
    background: #f5f5f5;
    border-radius: 20px;
    font-size: 13px;
    line-height: 20px;
    color: #121525;
    font-weight: 400;
    cursor: pointer;
    position: relative;
}
.filter_dropdown i{
    font-size: 11px;
    margin-left: 10px;
}
.filter_dropdown .dropdown_wpr{
    min-width: 160px;
    left: auto;
    right: 0;
    z-index: 3;
}
.filter_dropdown .dropdown_wpr:before{
    display: none;
}
.filter_dropdown .dropdown_wpr ul li{
    padding: 5px 10px;
}

@media(max-width: 767px) {
    .empty_section h1 {
        font-size: 15px !important;
        line-height: 25px !important;
    }
}

@media(max-width: 499px) {
    .empty_section h1 {
        font-size: 13px !important;
        line-height: 23px !important;
    }
}
</style>